import { useCallback, useEffect, useMemo } from 'react'
import { type FetcherWithComponents, useFetcher } from 'react-router'
import type { z } from 'zod'
import type { ActionResult } from '~/utils/actions.server'

type OptimisticValues<T> = {
  [K in keyof T]: T[K] | null
}

type SubmitConfig = Parameters<FetcherWithComponents<unknown>['submit']>[1]

type SubmitData<Schema> = Schema extends z.ZodObject<any>
  ? Partial<z.input<Schema>>
  : Record<string, any>

export const useFetcherForm = <
  Result,
  Schema extends z.ZodObject<any> = z.ZodObject<any>,
>({
  intent,
  key,
  config,
  onSuccess = () => undefined,
  onError = () => undefined,
  schema,
}: {
  intent: string
  key?: string
  config?: SubmitConfig
  onSuccess?: (data: Result) => void
  onError?: () => void
  schema?: Schema
}) => {
  const fetcherKey = key ?? intent

  const fetcher = useFetcher<ActionResult<Result>>({ key: fetcherKey })

  const isSubmitting = fetcher.state !== 'idle'

  const submit = useCallback(
    (data?: SubmitData<Schema> | FormData, extraConfig?: SubmitConfig) => {
      const submitConfig: SubmitConfig = {
        method: 'POST',
        ...config,
        ...extraConfig,
      }
      if (data instanceof FormData) {
        data.append('intent', intent)
        fetcher.submit(data, submitConfig)
        return
      }
      fetcher.submit({ ...data, intent }, submitConfig)
    },
    [fetcher.submit, config, intent]
  )

  const optimisticValues = useMemo(() => {
    if (!schema || !fetcher.formData) return null

    const shape = schema.shape
    const result: Record<string, unknown> = {}

    for (const key in shape) {
      if (!Object.hasOwn(shape, key)) continue
      const value = fetcher.formData.get(key)
      try {
        result[key] =
          value !== null && value !== undefined ? shape[key].parse(value) : null
      } catch {
        console.log(`Failed to parse ${key} with value ${value}`)
        result[key] = null
      }
    }

    return result as OptimisticValues<z.infer<NonNullable<Schema>>>
  }, [schema, fetcher.formData])

  const onSuccessCallback = useCallback(onSuccess, [])
  const onErrorCallback = useCallback(onError, [])

  useEffect(() => {
    if (fetcher.data?.ok) {
      onSuccessCallback(fetcher.data.data)
    }

    if (fetcher.data && !fetcher.data.ok) {
      onErrorCallback()
    }
  }, [fetcher.data, onSuccessCallback, onErrorCallback])

  return { submit, isSubmitting, optimisticValues, fetcher }
}
